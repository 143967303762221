
























































































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Paystub} from "@/dto/payroll/PayrollDTO";
import PayrollReportControlPanelModal
	from "@/components/payroll/business/employments/employee/PayrollReportControlPanelModal.vue";

@Component
export default class PaystubReportView extends Vue {

	@Prop()
	private report!: Paystub

	@Prop({default: true})
	private showControlButton!: boolean

	@Prop({default: function () {return;}})
	private callBack!: () => void

	private showControlPanel() {
		this.$modal.show(
			PayrollReportControlPanelModal,
			{
				report: this.report,
				callBack: () => this.callBack
			}
		)
	}

}

