

































































import {Component, Vue} from 'vue-property-decorator';
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "@/utils/StringUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {SickLeave, SickLeaveFilter} from "@/components/payroll/sickLeave/SickLeave";
import SickLeaveOptionsModal from "@/components/payroll/sickLeave/SickLeaveOptionsModal.vue";
import SickLeaveCreationModal from "@/components/payroll/sickLeave/SickLeaveCreationModal.vue";

@Component({
	components: {PortalDate, PaginationComponent},
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		prettyEnum
	}
})
export default class SickLeaveList extends Vue {

	private sickLeaves: Array<SickLeave> = [];
	private totalPages = 0

	private employments: Array<IEmployment> = []

	private filter = new SickLeaveFilter(
		{
			employerId: this.$wss.getCurrent.id,
			startDate: moment().format("YYYY-MM-DD")
		}
	)

	private async loadSickLeaves() {
		Application.startLoading()
		return SickLeaveService.getPageByFilter(this.filter).then(
			res => {
				this.sickLeaves = res.data.data
				this.totalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadSickLeaves()
			.then(this.loadEmployments)
	}

	private loadEmployments() {
		Application.startLoading()
		EmploymentService.getAllByFilter(
			new EmploymentFilter(
				{
					employerId: this.$wss.getCurrent.id,
					status: [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS],
				}
			)
		).then(
			res => {
				this.employments = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private showOptions(sickLeave: SickLeave) {
		this.$modal.show(
			SickLeaveOptionsModal,
			{
				sickLeave: sickLeave,
				callback: () => this.loadSickLeaves()
			}
		)
	}

	private showCreationWindow() {
		this.$modal.show(
			SickLeaveCreationModal,
			{
				employments: this.employments,
				callback: () => this.loadSickLeaves()
			}
		)
	}

	private reset() {
		this.filter = new SickLeaveFilter(
			{
				employerId: this.$wss.getCurrent.id,
				startDate: moment().format("YYYY-MM-DD")
			}
		)
		this.loadSickLeaves()
	}

	private changePage(page: number){
		this.filter.page = page - 1;
	    this.loadSickLeaves();
	}
}

