















import {Component, Prop, Vue} from "vue-property-decorator";
import {TimeSheetFilter} from "@/dto/payroll/timesheet/TimesheetPayloads";
import PortalDate from "@/components/common/PortalDate.vue";
import PayrollService from "@/services/payroll/PayrollService";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import PortalTime from "@/components/common/PortalTime.vue";

@Component({
	components: {PortalTime, PortalDate}
})
export default class TimeSheetReportCreationModal extends Vue {
	@Prop()
	private filter!: TimeSheetFilter

	private createReport() {
		Application.startLoading()
		PayrollService.createTimesheetReport(this.filter).then(
			res => {
				this.$docUtils.download(res.data.id)
				Application.stopLoading();
				this.$modal.hideAll()
			},
			err => processError(err, this)
		)
	}
}

