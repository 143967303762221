import FileService from "@/services/FileService";
import {FILE_PUBLISH_DOCUMENT_ENDPOINT} from "@/constants/endpoints";
import moment from "moment";
import authHeader from "@/services/auth-header";
import Application from "@/state/Application";

export class DocumentUtils {

    public download(id: number) {
        Application.stopLoading()
        FileService.publishDocument(id).then(
            res => {
                const link = document.createElement('a')
                const token = authHeader().Authorization
                link.href = `${FILE_PUBLISH_DOCUMENT_ENDPOINT}/${res.data}${token? "?token=" + token : ''}`
                if (!(/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && /webOS|iPhone|iPad|iPod/i.test(navigator.userAgent))) {
                    link.setAttribute('target', '_blank')
                } else {
                    link.setAttribute('download', '')
                }
                document.body.appendChild(link)
                Application.stopLoading()
                link.click()
            },
            err => {
                console.error(err)
                Application.stopLoading()
            }
        )
    }
}

export default new DocumentUtils();