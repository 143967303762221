import {IEmployment} from "@/dto/payroll/IEmployment";

export class SickLeaveFilter {
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    employerId: number | null = null;
    employmentsId: Array<number> = [];
    page = 0;
    size = 20

    constructor(init?: Partial<SickLeaveFilter>) {
        Object.assign(this, init)
    }
}

export class SickLeave {
    id!: number;
    startDate!: Date;
    endDate!: Date;
    paid!: boolean;
    employment!: IEmployment
}

export class CreateSickLeavePayload {
    startDate: Date | null = null;
    endDate: Date | null = null;
    employmentId: number | null = null;
    isPaid = false;

    constructor(init?: Partial<CreateSickLeavePayload>) {
        Object.assign(this, init)
    }
}

export class SickLeaveBalance {
    id!: number;
    balance!: number;
    carryover!: number;
    totalBalanceHours!: string;
    totalBalanceDays!: string
}

export class UpdateSickLeaveBalancePayload {
    employmentId: number | null = null;
    carryover: number | null = null;

    constructor(init?: Partial<UpdateSickLeaveBalancePayload>) {
        Object.assign(this, init)
    }
}
