import { v4 as uuidv4 } from 'uuid';
import Workspaces from "@/state/Workspaces";
import {DateTime} from "luxon";

const UUID_KEY = "uuid";

export default function authHeader() {

    const storedUser = localStorage.getItem('user');
    const user = JSON.parse(storedUser ? storedUser : "{}");

    if (user && user.accessToken) {
        if (Workspaces.getCurrent) {
            return {
                Authorization: 'Bearer ' + user.accessToken,
                Workspace: Workspaces.getCurrent.id,
                TimeZone: DateTime.local().zoneName
            }
        } else {
            return {
                Authorization: 'Bearer ' + user.accessToken,
                TimeZone: DateTime.local().zoneName
            }
        }
    } else {
        return {};
    }
}

export function getCustomerUUID(): string {
    if (localStorage.getItem(UUID_KEY)) {
        return localStorage.getItem(UUID_KEY) as string;
    } else {
        const uuid = uuidv4();
        localStorage.setItem(UUID_KEY, uuid);
        return uuid
    }
}

export function authorization() {

    const storedUser = localStorage.getItem('user');
    const user = JSON.parse(storedUser ? storedUser : "");

    if (user && user.accessToken) {
        return 'Bearer ' + user.accessToken;
    } else {
        return "";
    }

}
