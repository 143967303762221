

































































import {Component, Vue} from 'vue-property-decorator';
import {VacationDto, VacationFilter} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import VacationOptionsModal from "@/components/payroll/vacation/VacationOptionsModal.vue";
import VacationCreationModal from "@/components/payroll/vacation/VacationCreationModal.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "@/utils/StringUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import VacationService from "@/services/payroll/VacationService";

@Component({
	components: {PortalDate, PaginationComponent},
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		prettyEnum
	}
})
export default class VacationList extends Vue {

	private vacations: Array<VacationDto> = [];
	private totalPages = 0

	private employments: Array<IEmployment> = []

	private filter = new VacationFilter(
		{
			employerId: this.$wss.getCurrent.id,
			startDate: moment().format("YYYY-MM-DD")
		}
	)

	private async loadVacations() {
		Application.startLoading()
		return VacationService.getPageOfVacations(this.filter).then(
			res => {
				this.vacations = res.data.data
				this.totalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadVacations()
			.then(this.loadEmployments)
	}

	private loadEmployments() {
		Application.startLoading()
		EmploymentService.getAllByFilter(
			new EmploymentFilter(
				{
					employerId: this.$wss.getCurrent.id,
					status: [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS],
				}
			)
		).then(
			res => {
				this.employments = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private showOptions(vacation: VacationDto) {
		this.$modal.show(
			VacationOptionsModal,
			{
				vacation: vacation,
				callback: () => this.loadVacations()
			}
		)
	}

	private showCreationWindow() {
		this.$modal.show(
			VacationCreationModal,
			{
				employments: this.employments,
				callback: () => this.loadVacations()
			}
		)
	}

	private reset() {
		this.filter = new VacationFilter(
			{
				employerId: this.$wss.getCurrent.id,
				startDate: moment().format("YYYY-MM-DD")
			}
		)
		this.loadVacations()
	}

	private changePage(page: number){
		this.filter.page = page - 1;
		this.loadVacations();
	}
}

