import SelectOption from "@/components/common/SelectOption";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {Earning, HourItem, TarifficationPair} from "@/dto/payroll/PayrollDTO";

export class CreateGeneralPayrollReportTask {
    startDate: Date | null = null;
    endDate: Date | null = null;
    workPlaceIds: Array<number> = [];
    branchIds: Array<number> = [];
    sources: Array<SourceType> = [];
    employerIds: Array<number> = [];
    employmentsIds: Array<number> = [];
    groupModes: Array<GroupMode> = [];

    constructor(init?: Partial<CreateGeneralPayrollReportTask>) {
        Object.assign(this, init)
    }
}

export enum SourceType {
    PAYCHECK = "PAYCHECK",
    VOID = "VOID",
    EDIT = "EDIT"
}

export enum GroupMode {
    EMPLOYMENT = "EMPLOYMENT",
    PAY_PERIOD = "PAY_PERIOD",
    PAY_DATE = "PAY_DATE",
    SOURCE = "SOURCE",
    LOCATION = "LOCATION",
    BRANCH = "BRANCH",
    TOTALS = "TOTALS"
}

export const GROUP_MODE_OPTIONS: Array<SelectOption> = [
    SelectOption.builder().title("Employment").value(GroupMode.EMPLOYMENT).build(),
    SelectOption.builder().title("Pay period").value(GroupMode.PAY_PERIOD).build(),
    SelectOption.builder().title("Pay date").value(GroupMode.PAY_DATE).build(),
    SelectOption.builder().title("Source").value(GroupMode.SOURCE).build(),
    SelectOption.builder().title("Location").value(GroupMode.LOCATION).build(),
    SelectOption.builder().title("Branch").value(GroupMode.BRANCH).build(),
    SelectOption.builder().title("Total").value(GroupMode.TOTALS).build()
]

export const SOURCE_TYPE_OPTIONS: Array<SelectOption> = [
    SelectOption.builder().title("Paycheck").value(SourceType.PAYCHECK).build(),
    SelectOption.builder().title("Voided Paycheck").value(SourceType.VOID).build(),
    SelectOption.builder().title("System Edit").value(SourceType.EDIT).build()
]

export enum ReportType {
    PAYROLL_DETAILS = "PAYROLL_DETAILS",
    PAYSTUB = "PAYSTUB",
    GENERAL = "GENERAL"
}

export const REPORT_TYPE_OPTIONS: Array<SelectOption> = [
    SelectOption.builder().title("Payroll details").value(ReportType.PAYROLL_DETAILS).build(),
    SelectOption.builder().title("Paystub").value(ReportType.PAYSTUB).build(),
    SelectOption.builder().title("General").value(ReportType.GENERAL).build()
]


export class GeneralPayrollReport implements PayrollReport{
    id: number | null = null;
    reportStartDate!: Date;
    reportEndDate!: Date;
    reportDefs!: Array<GeneralPayrollReportItem>;
    fileMeta!: FileMetaDTO;
    reportEnd!: string;
    reportStart!: string;
    reportType!: ReportType;
}

export class GeneralPayrollReportItem {
    transactionDate!: string;
    payDate!: string;
    payPeriodStart!: string;
    payPeriodEnd!: string;
    source!: string;
    employment!: EmployeeDto;
    hours!: Array<HourItem>;
    totalHours!: string;
    earnings!: Array<Earning>;
    taxableTotal!: string;
    expenseReimburse!: string;
    total!: string;
    employeeTaxes!: Array<TarifficationPair>;
    employeeWages!: Array<TarifficationPair>;
    totalEmployeeTax!: string;
    netPay!: string;
    employerTaxes!: Array<TarifficationPair>;
    employerWages!: Array<TarifficationPair>;
    totalEmployerTax!: string;
    groupModes!: string;
    keys!: Array<TarifficationPair>
}

export class PayrollReportFilter {
    employerIds: Array<number> = [];
    employmentIds: Array<number> = []
    reportStart: Date | null = null
    reportEnd: Date | null = null
    type: ReportType | null = null
    page = 0
    size = 20

    constructor(init?: Partial<PayrollReportFilter>) {
        Object.assign(this, init)
    }
}

export interface SimplePayrollReport extends PayrollReport{
    payrollId: number
}

export interface PayrollReport {
    id: number | null
    reportStart: string,
    reportEnd: string,
    reportType: ReportType
    fileMeta: FileMetaDTO
}
