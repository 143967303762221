












import {Component, Prop, Vue} from "vue-property-decorator";
import {PolicySignature} from "@/dto/payroll/policies/PTOPolicyDTO";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import {EmploymentType} from "@/constants/EmploymentType";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {CounterpartyType} from "@/constants/CounterpartyType";
import {
	BusinessContractorDTO,
	IndividualContractorDTO
} from "@/components/payroll/business/employments/contractors/Contractor";
import {
	BusinessForeignContractorDTO,
	IndividualForeignContractorDTO
} from "@/components/payroll/business/employments/foreignContractors/ForeignContractorPayloads";
import PolicyService from "@/services/payroll/PolicyService";

export type mode = "PTO" | "SLP"

@Component
export default class PolicySignatureOptions extends Vue {

	@Prop()
	private signature!: PolicySignature

	@Prop()
	private callback!: () => void

	@Prop({default: "PTO"})
	private mode!: mode

	private disable() {
		this.$modal.show(
			DeleteConfirmation,
			{
				onDelete: () => this.disableSignature(),
				targetName: `signature`,
				operationName: "disable"
			}
		)
	}

	private disableSignature() {
		console.log(this.mode)
		switch (this.mode) {
			case "PTO":
				Application.startLoading()
				PolicyService.disablePTOSignature(this.signature.id, this.signature.employment.id!).then(
					() => {
						this.$modal.hideAll();
						Application.stopLoading();
						this.callback()
					},
					err => processError(err, this)
				)
				break;
			case "SLP":
				Application.startLoading()
				PolicyService.disableSLPSignature(this.signature.id, this.signature.employment.id!).then(
					() => {
						this.$modal.hideAll();
						Application.stopLoading();
						this.callback()
					},
					err => processError(err, this)
				)
		}
	}

	private toProfile() {
		switch (this.signature.employment.employmentType) {
			case EmploymentType.STAFF:
				this.$router.push(new EmployeeDto(
					{
						id: this.signature.employment.id
					}
				).route)
				break;
			case EmploymentType.CONTRACTOR:
				if ((this.signature.employment as IndividualContractorDTO).counterpartyType == CounterpartyType.PERSON) {
					this.$router.push(new IndividualContractorDTO(
						{
							id: this.signature.employment.id
						}
					).route)
				} else {
					this.$router.push(new BusinessContractorDTO({
						id: this.signature.id
					}).route)
				}
				break;
			case EmploymentType.FOREIGN:
				if ((this.signature.employment as IndividualForeignContractorDTO).counterpartyType == CounterpartyType.PERSON) {
					this.$router.push(new IndividualForeignContractorDTO({
						id: this.signature.employment.id
					}).route)
				} else {
					this.$router.push(new BusinessForeignContractorDTO({
						id: this.signature.employment.id
					}).route)
				}
		}
		this.$modal.hideAll()
	}

}

