import _Vue from "vue";
import DateUtils from "@/utils/DateUtils";
import DocumentUtils from "@/utils/DocumentUtils";
import Workspaces from "@/state/Workspaces";
import Application from "@/state/Application";
import Clipboard from "@/state/Clipboard";
import StringUtils from "@/utils/StringUtils";

/**
 * Declare types at vue-plugins.d.ts
 * */
export function UtilsVuePlugin(Vue: typeof _Vue, options?: any): void {

    Vue.prototype.$dateUtils = DateUtils
    Vue.prototype.$docUtils = DocumentUtils
    Vue.prototype.$stringUtils = StringUtils
    Vue.prototype.$wss = Workspaces
    Vue.prototype.$app = Application
    Vue.prototype.$clipboard = Clipboard

}
