























import {Component, Prop, Vue} from "vue-property-decorator";
import {PolicySignature, PTOPolicy, PTOSignature} from "@/dto/payroll/policies/PTOPolicyDTO";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import RouteNames from "@/router/RouteNames";
import EmbeddedFile from "@/components/common/EmbeddedFile.vue";
import SickLeaveAttachModal from "@/components/payroll/policies/sickLeave/SickLeaveAttachModal.vue";
import PTOAttachModal from "@/components/payroll/policies/pto/PTOAttachModal.vue";
import PolicyService from "@/services/payroll/PolicyService";

@Component({
	components: {EmbeddedFile}
})
export default class PTOView extends Vue {

	@Prop()
	private employmentId!: number | null

	private signature: PTOSignature | null = null;

	private loadPTOByEmploymentId() {
		Application.startLoading()
		PolicyService.getPTOPolicyByEmploymentId(this.employmentId!).then(
			res => {
				this.signature = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadPTOByEmploymentId()
	}


	private openAttachModal() {
		this.$modal.show(
			PTOAttachModal,
			{
				employmentId: this.employmentId,
				callback: () => this.loadPTOByEmploymentId()
			}
		)
	}


	private toSignPage() {
		this.$router.push({name: RouteNames.SIGN_PUBLIC, params: {publicId: this.signature!.publicId}})
	}

}

