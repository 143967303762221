import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import InsuranceSettingsDTO from "@/dto/archive/insurance/InsuranceSettingsDTO";

@Module({ namespaced: true })
class Insurance extends VuexModule {

    public settings = new InsuranceSettingsDTO();

    @Mutation
    public setSettings(newSettings: InsuranceSettingsDTO): void {
        this.settings = {
            generalLiabilityRequired: newSettings.generalLiabilityRequired !== false,
            professionalLiabilityRequired: newSettings.professionalLiabilityRequired !== false,
            workersCompensationRequired: newSettings.workersCompensationRequired !== false,
            disabilityRequired: newSettings.disabilityRequired !== false,
        };
    }

}


export default Insurance;
