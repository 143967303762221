const URL = process.env.VUE_APP_SA_PORTAL_BACKEND_URL || '';
const API_URL = `${URL}/api`;

const AUTH_SERVICE = `${API_URL}/auth`;
const AUDIT_SERVICE = `${API_URL}/audit`;
const ADMIN_SERVICE = `${API_URL}/admin`;
const TAXATION_SERVICE = `${API_URL}/taxation`;
const FILE_SERVICE = `${API_URL}/file`;
const PROFILE_SERVICE = `${API_URL}/profile`;
const PERSONAL_TAXATION_SERVICE = `${TAXATION_SERVICE}`;
const BUSINESS_TAXATION_SERVICE = `${TAXATION_SERVICE}/business`;
const PUBLIC_SERVICE = `${API_URL}/pub`;
const INDIVIDUAL_TAXATION_SERVICE = `${TAXATION_SERVICE}/individual`;
const PERSON_SERVICE = `${API_URL}/person`;
const PAYROLL_SERVICE = `${API_URL}/payroll`;
const COMPANY_SERVICE = `${API_URL}/company`;
const DOCUMENTS_SERVICE = `${API_URL}/documents`;
const EMPLOYMENT_SERVICE = `${API_URL}/employment`;
const SALES_TAX_SERVICE = `${API_URL}/salestax`;
const COUNTERPARTY_SERVICE = `${API_URL}/counterparty`;
const CHAT_SERVICE = `${API_URL}/chat`;
const REPORT_SERVICE = `${API_URL}/reports`;
const REQUEST_SERVICE = `${API_URL}/request`;
const EMPLOYEE_SERVICE = `${EMPLOYMENT_SERVICE}/employees`;
const CONTRACTOR_SERVICE = `${EMPLOYMENT_SERVICE}/contractor`;
const FOREIGN_CONTRACTOR_SERVICE = `${EMPLOYMENT_SERVICE}/foreign_contractor`;
const MAIL_SERVICE = `${API_URL}/mail`;
const TARIFFICATION_SERVICE = `${API_URL}/tariffication`;
const INSURANCE_SERVICE = `${API_URL}/insurances`;
const PAYMENTS_SERVICE = `${API_URL}/payments`;
const SIGNING_SERVICE = `${API_URL}/signing`;
const FINCEN_SERVICE = `${API_URL}/fincen`;

export const AUDIT_EVENTS_ENDPOINT = `${AUDIT_SERVICE}`;
export const ADMIN_ENDPOINT = `${ADMIN_SERVICE}`
export const ADMIN_PORTAL_MAIL_SERVICE_ENDPOINT = `${ADMIN_SERVICE}/portal_mail_service`;
export const ADMIN_OFFICES_ENDPOINT = `${ADMIN_SERVICE}/offices`;
export const ADMIN_EMPLOYEES_ENDPOINT = `${ADMIN_SERVICE}/employees`;
export const ADMIN_ASSIGNMENTS_ENDPOINT = `${ADMIN_SERVICE}/assignments`;
export const ADMIN_ROLES_ENDPOINT = `${ADMIN_SERVICE}/roles`;

export const AUTH_SIGN_IN_ENDPOINT = `${AUTH_SERVICE}/signin`;
export const AUTH_SIGN_UP_ENDPOINT = `${AUTH_SERVICE}/signup`;
export const AUTH_LOGOUT_ENDPOINT = `${AUTH_SERVICE}/logout`;
export const AUTH_REFRESH_TOKEN_ENDPOINT = `${AUTH_SERVICE}/refreshtoken`;
export const AUTH_CONFIRM_EMAIL_ENDPOINT = `${AUTH_SERVICE}/mail/confirm`;
export const AUTH_INVITATION_ENDPOINT = `${AUTH_SERVICE}/invitation`;
export const AUTH_INVITATION_COMPLETION_ENDPOINT = `${AUTH_SERVICE}/invitation/completion`;
export const AUTH_RESET_PASSWORD_REQUEST_ENDPOINT = `${AUTH_SERVICE}/password/requestreset`;
export const AUTH_RESET_PASSWORD_ENDPOINT = `${AUTH_SERVICE}/password/reset`;
export const AUTH_CHANGE_PASSWORD_ENDPOINT = `${AUTH_SERVICE}/password/change`;
export const AUTH_USER_ENDPOINT = `${AUTH_SERVICE}/user`;
export const AUTH_ACCOUNT_ENDPOINT = `${AUTH_SERVICE}/account`;
export const AUTH_EMAIL_ENDPOINT = `${AUTH_SERVICE}/email`;
export const AUTH_NOTIFICATION_ENDPOINT = `${AUTH_SERVICE}/notification`;

export const TAXATION_QUESTIONNAIRE_ENDPOINT = `${PERSONAL_TAXATION_SERVICE}/questionnaire`;
export const TAXATION_FORM_LIST_ENDPOINT = `${PERSONAL_TAXATION_SERVICE}/form/list`;
export const TAXATION_GET_QUESTIONNAIRE_LIST_ENDPOINT = `${PERSONAL_TAXATION_SERVICE}/questionnaire/list`;
export const TAXATION_SAVE_FORM_VALUE_ENDPOINT = `${PERSONAL_TAXATION_SERVICE}/questionnaire/form/save`;
export const TAXATION_ASSIGN_QUESTIONNAIRE_ENDPOINT = `${PERSONAL_TAXATION_SERVICE}/questionnaire/assign`;
export const TAXATION_PAGES_ENDPOINT = `${PERSONAL_TAXATION_SERVICE}/questionnaire/pages`;
export const TAXATION_SUBMIT_QUESTIONNAIRE_ENDPOINT = `${PERSONAL_TAXATION_SERVICE}/questionnaire/submit`;

export const INDIVIDUAL_TAXATION_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}`;
export const INDIVIDUAL_TAXATION_PUBLIC_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}/p`;

export const TAXATION_INDIVIDUAL_QUESTIONNAIRE_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}/questionnaires`;
export const TAXATION_INDIVIDUAL_QUESTIONNAIRE_PUBLIC_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}/public/questionnaires`;
export const TAXATION_INDIVIDUAL_QUESTIONNAIRE_LIST_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}/questionnaires/list`;
export const TAXATION_INDIVIDUAL_QUESTIONNAIRE_PAGES_COUNT_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}/questionnaires/pages`;
export const TAXATION_INDIVIDUAL_BATCH_UPDATE_JOB_TABLE_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}/batch/updateJobTable`;
export const TAXATION_INDIVIDUAL_BATCH_CREATE_QUESTIONNAIRES_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}/batch/createQuestionnaires`;
export const TAXATION_INDIVIDUAL_BATCH_SEND_NOTIFICATIONS_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}/batch/sendNotifications`;

export const TAXATION_INDIVIDUAL_ETP_ENDPOINT = `${INDIVIDUAL_TAXATION_SERVICE}/estimated`;
export const TAXATION_INDIVIDUAL_ETP_OFFER_ENDPOINT = `${TAXATION_INDIVIDUAL_ETP_ENDPOINT}/p/offer`;
export const TAXATION_INDIVIDUAL_ETP_PAYMENTS_ENDPOINT = `${TAXATION_INDIVIDUAL_ETP_ENDPOINT}/payments`;

export const FILE_ENDPOINT = FILE_SERVICE;
export const FILE_UPLOAD_ENDPOINT = `${FILE_SERVICE}/upload`;
export const FILE_DOWNLOAD_ENDPOINT = `${FILE_SERVICE}/download`;
export const FILE_USER_FILES_LIST_ENDPOINT = `${FILE_SERVICE}/user/list`;
export const FILE_CUSTOM_ENDPOINT = `${FILE_SERVICE}/custom`;
export const FILE_ARCHIVE_ENDPOINT = `${FILE_SERVICE}/archive`
export const FILE_ARCHIVE_LIST_ENDPOINT = `${FILE_ARCHIVE_ENDPOINT}/list`
export const FILE_SIGNABLE_ENDPOINT = `${FILE_SERVICE}/signable`;
export const FILE_SIGNABLE_LIST_ENDPOINT = `${FILE_SERVICE}/signable/list`;
export const FILE_PUBLISH_LINK_ENDPOINT = `${FILE_SERVICE}/publish`;
export const FILE_PUBLISH_DOCUMENT_ENDPOINT = `${FILE_SERVICE}/public/download`;

export const PROFILE_PERSONAL_DATA_ENDPOINT = `${PROFILE_SERVICE}/personalData`;
export const PROFILE_DEPENDANTS_ENDPOINT = `${PROFILE_SERVICE}/dependants`;
export const PROFILE_USERS_ENDPOINT = `${PROFILE_SERVICE}/users`;
export const PROFILE_MODERATORS_ENDPOINT = `${PROFILE_SERVICE}/moderators`;
export const PROFILE_CUSTOMERS_ENDPOINT = `${PROFILE_SERVICE}/customers`;
export const PROFILE_COMPANIES_ENDPOINT = `${PROFILE_SERVICE}/companies`;
export const PROFILE_BANK_ACCOUNTS_ENDPOINT = `${PROFILE_SERVICE}/bankaccounts`;
export const PROFILE_COMPANY_ENDPOINT = `${PROFILE_SERVICE}/company`;
export const PROFILE_USERS_PAGES_ENDPOINT = `${PROFILE_SERVICE}/users/pages`;

export const BUSINESS_TAXATION_QUESTIONNAIRE_LIST_ENDPOINT = `${BUSINESS_TAXATION_SERVICE}/questionnaire/list`;
export const BUSINESS_TAXATION_QUESTIONNAIRE_ENDPOINT = `${BUSINESS_TAXATION_SERVICE}/questionnaire`;
export const BUSINESS_TAXATION_ADMIN_QUESTIONNAIRE_LIST_ENDPOINT = `${BUSINESS_TAXATION_SERVICE}/admin/questionnaire/list`;
export const BUSINESS_TAXATION_ASSIGN_QUESTIONNAIRE_ENDPOINT = `${PERSONAL_TAXATION_SERVICE}/business/questionnaire/assign`;
export const BUSINESS_TAXATION_PAGES_ENDPOINT = `${PERSONAL_TAXATION_SERVICE}/business/questionnaire/pages`;
export const BUSINESS_TAXATION_SUBMIT_QUESTIONNAIRE_ENDPOINT = `${BUSINESS_TAXATION_SERVICE}/questionnaire/submit`

export const PUBLIC_FEEDBACK_ENDPOINT = `${PUBLIC_SERVICE}/feedback`;
export const TEMP_AUTH_FEEDBACK_ENDPOINT = `${AUTH_SERVICE}/feedback`;
export const TEMP_AUTH_COMPLETE_PAYMENT_ENDPOINT = `${AUTH_SERVICE}/payment`;

export const PERSON_ENDPOINT = `${PERSON_SERVICE}`;
export const PERSON_ADMIN_ENDPOINT = `${PERSON_SERVICE}/admin`;
export const PERSON_DETAILS_ENDPOINT = `${PERSON_SERVICE}/details`;
export const PERSON_BATCH_ENDPOINT = `${PERSON_SERVICE}/batch`;
export const PERSON_EMPLOYEES_ENDPOINT = `${PERSON_SERVICE}/employees`;
export const PERSON_EMPLOYEE_ENDPOINT = `${PERSON_SERVICE}/employee`;
export const PERSON_CUSTOMERS_ENDPOINT = `${PERSON_SERVICE}/customers`;
export const PERSON_CUSTOMERS_PAGES_ENDPOINT = `${PERSON_SERVICE}/customers/pages`;
export const PERSON_MARITAL_STATUS_ENDPOINT = `${PERSON_SERVICE}/marital`;
export const PERSON_ACCOUNT_ENDPOINT = `${PERSON_SERVICE}/email`;
export const PERSON_XLSX_ENDPOINT = `${PERSON_SERVICE}/xlsx`;
export const PERSON_EAD_ENDPOINT = `${PERSON_SERVICE}/ead`;
export const PERSON_ID_ENDPOINT = `${PERSON_SERVICE}/id`;
export const PERSON_GREENCARD_ENDPOINT = `${PERSON_SERVICE}/greencard`;
export const PERSON_PASSPORT_ENDPOINT = `${PERSON_SERVICE}/uspassport`;
export const PERSON_SSN_ENDPOINT = `${PERSON_SERVICE}/ssn`;
export const PERSON_RESIDENCY_STATUS_ENDPOINT = `${PERSON_SERVICE}/residency`
export const PERSON_FOREIGN_ID_ENDPOINT = `${PERSON_SERVICE}/foreignid`

export const PAYROLL_ENDPOINT_V1 = `${PAYROLL_SERVICE}/v1`;
export const PAYROLL_PAYSTUB_ENDPOINT = `${PAYROLL_ENDPOINT_V1}/paystub`;
export const PAYROLL_DETAILS_ENDPOINT = `${PAYROLL_ENDPOINT_V1}/details`;
export const PAYROLL_REPORTS_ENDPOINT = `${PAYROLL_ENDPOINT_V1}/reports`;
export const PAYROLL_REPORTS_PERIODS_ENDPOINT = `${PAYROLL_REPORTS_ENDPOINT}/periods`;
export const PAYROLL_INCOME_TAX_ENDPOINT = `${PAYROLL_ENDPOINT_V1}/incometax`;
export const PAYROLL_INCOME_TAX_LIST_ENDPOINT = `${PAYROLL_INCOME_TAX_ENDPOINT}/list`;
export const PAYROLL_INCOME_TAX_YEARS_ENDPOINT = `${PAYROLL_INCOME_TAX_ENDPOINT}/years`;
export const PAYROLL_FORM_1042S_ENDPOINT = `${PAYROLL_ENDPOINT_V1}/1042s`;
export const PAYROLL_FORM_K1_ENDPOINT = `${PAYROLL_ENDPOINT_V1}/k1`;
export const PAYROLL_FORM_1099_ENDPOINT = `${PAYROLL_ENDPOINT_V1}/1099`;
export const PAYROLL_ENDPOINT_V2 = `${PAYROLL_SERVICE}/v2`;

const PAYROLL_POLICY = `${PAYROLL_SERVICE}/v2/policy`
export const PAYROLL_PTO_POLICY_ENDPOINT = `${PAYROLL_POLICY}/pto`;
export const PAYROLL_SLP_POLICY_ENDPOINT = `${PAYROLL_POLICY}/slp`;
export const PAYROLL_VACATION_ENDPOINT = `${PAYROLL_ENDPOINT_V2}/vacation`;
export const PAYROLL_SICK_ENDPOINT = `${PAYROLL_ENDPOINT_V2}/sick_leave`
export const PAYROLL_DETAILS_ENDPOINT_V2 = `${PAYROLL_ENDPOINT_V2}/details`;
export const PAYROLL_PAYSTUB_ENDPOINT_V2 = `${PAYROLL_ENDPOINT_V2}/paystub`;
export const PAYROLL_PREVIEW_PAYROLL_ENDPOINT_V2 = `${PAYROLL_ENDPOINT_V2}/preview`;
export const PAYROLL_DEMO_PAYSTUB_ENDPOINT_V2 = `${PAYROLL_ENDPOINT_V2}/paystub/demo`
export const PAYROLL_GENERAL_DETAILS_ENDPOINT_V2 = `${PAYROLL_ENDPOINT_V2}/general_details`;
export const TIMESHEET_SERVICE = `${PAYROLL_ENDPOINT_V2}/timesheet`

export const COMPANY_ENDPOINT = `${COMPANY_SERVICE}`;
export const COMPANY_ENDPOINT_SHAREHOLDERS = `${COMPANY_SERVICE}/shareholders`;
export const COMPANY_ENDPOINT_ADDRESS = `${COMPANY_SERVICE}/address`;
export const COMPANY_BUSINESS_TAX_RETURN = `${COMPANY_SERVICE}/business_tax_returns`;
export const COMPANY_BANK_STATEMENTS = `${COMPANY_SERVICE}/bank_statements`;
export const COMPANY_BANK_ACCOUNT = `${COMPANY_SERVICE}/bank_account`;
export const COMPANY_DETAILS_ENDPOINT = `${COMPANY_SERVICE}/details`;
export const COMPANY_BATCH_ENDPOINT = `${COMPANY_SERVICE}/batch`;
export const COMPANY_ACCOUNT_ENDPOINT = `${COMPANY_ENDPOINT}/email`;
export const COMPANY_XLSX_ENDPOINT = `${COMPANY_ENDPOINT}/xlsx`;
export const COMPANY_OTHER_BANK_ACCOUNT_ENDPOINT = `${COMPANY_ENDPOINT}/other_bank_account`;

export const DOCUMENTS_REGISTER_ENDPOINT = `${DOCUMENTS_SERVICE}/register`;
export const DOCUMENTS_ACH_REQUEST_ENDPOINT = `${DOCUMENTS_SERVICE}/request/ach`;
export const DOCUMENTS_RESERVE_AGREEMENT_ENDPOINT = `${DOCUMENTS_SERVICE}/request/reserve_agreement`;
export const DOCUMENTS_PUBLIC_W8BEN_ENDPOINT = `${DOCUMENTS_SERVICE}/public/w8ben`;
export const DOCUMENTS_FILLABLE_ENDPOINT = `${DOCUMENTS_SERVICE}/fillable`;
export const DOCUMENTS_PUBLIC_FILLABLE_EXISTS_ENDPOINT = `${DOCUMENTS_SERVICE}/public/fillable/exists`;
export const DOCUMENTS_REGISTER_W9_ENDPOINT = `${DOCUMENTS_SERVICE}/register/w9`;
export const DOCUMENTS_PUBLIC_W9_ENDPOINT = `${DOCUMENTS_SERVICE}/public/w9`;
export const DOCUMENTS_PUBLIC_W4_ENDPOINT = `${DOCUMENTS_SERVICE}/public/w4`;
export const DOCUMENTS_W4_FILLING_ENDPOINT = `${DOCUMENTS_SERVICE}/w4/filling`;
export const DOCUMENTS_PUBLIC_W4_FILLING_ENDPOINT = `${DOCUMENTS_SERVICE}/public/w4/filling`;
export const DOCUMENTS_REQUEST_IT2104_ENDPOINT = `${DOCUMENTS_SERVICE}/request/it2104`;
export const DOCUMENTS_REGISTER_FORM_8822_B_ENDPOINT = `${DOCUMENTS_SERVICE}/register/f8822b`;
export const DOCUMENTS_PUBLIC_IT2104_ENDPOINT = `${DOCUMENTS_SERVICE}/public/it2104`;
export const DOCUMENTS_PUBLIC_FORM_ENDPOINT = `${DOCUMENTS_SERVICE}/public/form`
export const DOCUMENTS_AUTH_FORMS_ENDPOINT = `${DOCUMENTS_SERVICE}/auth`;
export const DOCUMENTS_FORM_8822_B_ENDPOINT = `${DOCUMENTS_SERVICE}/forms/f8822b`
export const DOCUMENTS_SIGNABLE_ENDPOINT = `${DOCUMENTS_SERVICE}/signable`;
export const DOCUMENTS_EXPIRABLE_ENDPOINT = `${DOCUMENTS_SERVICE}/expiring`;
export const DOCUMENTS_IRS_LETTERS = `${DOCUMENTS_SERVICE}/irs_letter`;
export const DOCUMENTS_ADMIN_IRS_LETTERS = `${DOCUMENTS_SERVICE}/admin/irs_letter`;
export const DOCUMENTS_RECEIPTS_ENDPOINT = `${DOCUMENTS_SERVICE}/receipts`;
export const DOCUMENTS_AGGREGATE_COMPANY_ENDPOINT = `${DOCUMENTS_SERVICE}/aggregate/company`;
export const FILE_SIGNABLE_REFRESH_ENDPOINT = `${DOCUMENTS_SERVICE}/signable/refresh`;

export const EMPLOYMENT_ENDPOINT = `${EMPLOYMENT_SERVICE}`;
export const EMPLOYMENT_BATCH_ENDPOINT = `${EMPLOYMENT_SERVICE}/batch`;

export const EMPLOYEE_ENDPOINT = `${EMPLOYEE_SERVICE}`;

export const GENERAL_CONTRACTOR_SERVICE = `${CONTRACTOR_SERVICE}`
export const BUSINESS_CONTRACTOR_SERVICE = `${CONTRACTOR_SERVICE}/business`;
export const INDIVIDUAL_CONTRACTOR_SERVICE = `${CONTRACTOR_SERVICE}/individual`;

export const GENERAL_FOREIGN_CONTRACTOR_SERVICE = `${FOREIGN_CONTRACTOR_SERVICE}`;
export const BUSINESS_FOREIGN_CONTRACTOR_SERVICE = `${FOREIGN_CONTRACTOR_SERVICE}/business`;
export const INDIVIDUAL_FOREIGN_CONTRACTOR_SERVICE = `${FOREIGN_CONTRACTOR_SERVICE}/individual`;

export const SALES_TAX_LIST_ENDPOINT = `${SALES_TAX_SERVICE}/list`;
export const SALES_TAX_YEARS_ENDPOINT = `${SALES_TAX_SERVICE}/years`;
export const SALES_TAX_ENDPOINT = `${SALES_TAX_SERVICE}`;
export const SALES_TAX_PERMIT_ENDPOINT = `${SALES_TAX_SERVICE}/permit`;
export const SALES_TAX_SUBSCRIPTION_ENDPOINT = `${SALES_TAX_SERVICE}/subscription`;
export const SALES_TAX_WORKFLOW_ENDPOINT = `${SALES_TAX_SERVICE}/workflow`;
export const SALES_TAX_WORKFLOW_KEY_ENDPOINT = `${SALES_TAX_SERVICE}/workflow/key`;
export const SALES_TAX_WORKFLOW_PUBLIC_STATEMENT_ENDPOINT = `${SALES_TAX_SERVICE}/workflow/p/statement`;
export const SALES_TAX_SCHEDULE_ENDPOINT = `${SALES_TAX_SERVICE}/schedule`;

export const COUNTERPARTY_ENDPOINT = `${COUNTERPARTY_SERVICE}`;
export const COUNTERPARTY_PROSPECTIVE_ENDPOINT = `${COUNTERPARTY_SERVICE}/prospective`;

export const CHAT_ENDPOINT = `${CHAT_SERVICE}`;
export const SOCKET_ENDPOINT = `${URL}/ws`;

export const REPORT_ENDPOINT = REPORT_SERVICE;

export const REQUESTS_ENDPOINT = `${REQUEST_SERVICE}`;

export const REQUESTS_RCCR_ENDPOINT = `${REQUEST_SERVICE}/rccr`;
export const REQUESTS_RCCR_DOCUMENTS_ENDPOINT = `${REQUESTS_RCCR_ENDPOINT}/documents`;
export const REQUESTS_RCCR_PUBLIC_ENDPOINT = `${REQUEST_SERVICE}/p`;

export const REQUESTS_CCDR_ENDPOINT = `${REQUEST_SERVICE}/ccdr`;
export const REQUESTS_CCDR_PUBLIC_ENDPOINT = `${REQUEST_SERVICE}/ccdr/p`;

export const REQUESTS_CBSR_ENDPOINT = `${REQUEST_SERVICE}/cbsr`;
export const REQUESTS_CBSR_PUBLIC_ENDPOINT = `${REQUEST_SERVICE}/cbsr/p`;
export const REQUESTS_CBSR_DOCUMENTS_ENDPOINT = `${REQUEST_SERVICE}/cbsr/documents`;

export const REQUESTS_W9CR_ENDPOINT = `${REQUEST_SERVICE}/w9cr`;
export const REQUESTS_W9CR_PUBLIC_ENDPOINT = `${REQUESTS_W9CR_ENDPOINT}/p`;
export const REQUESTS_W9CR_PUBLIC_CONTRACTOR_ENDPOINT = `${REQUESTS_W9CR_ENDPOINT}/p/contractor`;
export const REQUESTS_W9CR_CONTRACTOR_ENDPOINT = `${REQUESTS_W9CR_ENDPOINT}/contractor`;

export const REQUEST_RER_ENDPOINT = `${REQUEST_SERVICE}/rer`
export const REQUEST_RER_EMPLOYEE_ENDPOINT = `${REQUEST_RER_ENDPOINT}/employee`
export const REQUEST_RER_PUBLIC_ENDPOINT = `${REQUEST_RER_ENDPOINT}/p`
export const REQUEST_RER_PUBLIC_EMPLOYEE_ENDPOINT = `${REQUEST_RER_PUBLIC_ENDPOINT}/employee`

export const MAIL_REGISTRY_ENDPOINT = `${MAIL_SERVICE}/registry`;

export const TARIFFICATION_ENDPOINT = `${TARIFFICATION_SERVICE}`
export const TARIFFICATION_OVERRIDE_TAX_RATE = `${TARIFFICATION_ENDPOINT}/override/taxRate`

export const REQUEST_RIDC_ENDPOINT = `${REQUEST_SERVICE}/ridc`;
export const REQUEST_RIDC_CONTRACTORS_ENDPOINT = `${REQUEST_SERVICE}/ridc/contractors`;
export const REQUEST_RIDC_PUBLIC_ENDPOINT = `${REQUEST_RIDC_ENDPOINT}/p`;
export const REQUEST_RIDC_PUBLIC_CONTRACTORS_ENDPOINT = `${REQUEST_RIDC_PUBLIC_ENDPOINT}/contractors`;

export const BUSINESS_INSURANCE_ENDPOINT = `${INSURANCE_SERVICE}/business`;
export const BUSINESS_INSURANCE_SETTINGS_ENDPOINT = `${BUSINESS_INSURANCE_ENDPOINT}/settings`;
export const BUSINESS_INSURANCE_AGGREGATE_ENDPOINT = `${BUSINESS_INSURANCE_ENDPOINT}/aggregate`;

export const PAYMENTS_INVOICE_ENDPOINT = `${PAYMENTS_SERVICE}/invoices`;
export const PAYMENTS_INVOICE_PRICES_ENDPOINT = `${PAYMENTS_SERVICE}/prices`;
export const PAYMENTS_ENDPOINT = `${PAYMENTS_SERVICE}`;

export const SIGNING_DOCUMENT_SIGNER_PUBLIC_ENDPOINT = `${SIGNING_SERVICE}/p/document/signer`;
export const SIGNING_ENDPOINT = `${SIGNING_SERVICE}`;

export const FINCEN_ENDPOINT = `${FINCEN_SERVICE}`;
export const FINCEN_OFFERS_ENDPOINT = `${FINCEN_SERVICE}/offers`;
export const FINCEN_PUBLIC_ENDPOINT = `${FINCEN_SERVICE}/p`;
export const FINCEN_PUBLIC_OFFERS_ENDPOINT = `${FINCEN_PUBLIC_ENDPOINT}/offers`;
export const FINCEN_BOI_ENDPOINT = `${FINCEN_SERVICE}/boi`
