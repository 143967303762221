import Vue from 'vue';
import VueNotification from "vue-notification";
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {
    faEllipsisVertical,
    faClock,
    faPiggyBank,
    faCircleExclamation,
    faPen,
    faComment,
    faSpinner,
    faSquareCaretUp,
    faMagnifyingGlass,
    faQuestion,
    faAddressCard,
    faBaby,
    faBars,
    faBriefcase,
    faBuilding,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCrown,
    faFile,
    faDownload,
    faFileDownload,
    faHome,
    faIdCardAlt,
    faInfo,
    faInfoCircle,
    faKey,
    faLanguage,
    faList,
    faPenToSquare,
    faPlus,
    faRepeat,
    faRotate,
    faSignInAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faTable,
    faTimes,
    faTrash,
    faUser,
    faUserCircle,
    faUserPlus,
    faBell,
    faFlagCheckered,
    faLock,
    faBookmark,
    faEnvelope,
    faCloudArrowUp,
    faCloudArrowDown,
    faX,
    faBan,
    faHourglassHalf,
    faCopy,
    faTriangleExclamation,
    faArrowUpRightFromSquare,
    faGear,
    faRotateLeft,
    faCircleCheck,
    faFaceFrown,
    faEye,
    faEyeSlash,
    faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import AuthService from "@/services/AuthService";
import {authorization} from '@/services/auth-header';
import "@/assets/scss/styles.scss"
import VModal from 'vue-js-modal'
import {BootstrapVue, FormDatepickerPlugin, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from "./i18n"
import {UtilsVuePlugin} from "@/vue_plugins/UtilsVuePlugin";
import {ConstantsVuePlugin} from "@/vue_plugins/ConstantsVuePlugin";
import VueSignaturePad from "vue-signature-pad";

library.add(faEllipsisVertical, faClock, faPiggyBank, faCircleExclamation, faPen, faComment, faSquareCaretUp, faMagnifyingGlass,faSpinner, faDownload, faQuestion, faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faTimes, faCheck, faPlus, faKey, faLanguage, faInfo, faTable, faBars, faCaretDown,
    faCaretUp, faAddressCard, faBriefcase, faUserCircle, faIdCardAlt, faBaby, faBuilding, faFile, faTrash, faInfoCircle, faFileDownload, faSortUp, faSortDown,
    faSort, faCaretRight, faRepeat, faList, faCrown, faPen, faPenToSquare, faRotate, faBell, faFlagCheckered, faLock, faBookmark, faEnvelope, faCloudArrowUp, faCloudArrowDown,
    faX, faBan, faHourglassHalf, faCopy, faTriangleExclamation, faArrowUpRightFromSquare, faGear, faRotateLeft, faCircleCheck, faFaceFrown, faEye, faEyeSlash, faFileInvoiceDollar);


Vue.config.productionTip = false;

Vue.use(VeeValidate, {
    validity: true,
    errorBagName: 'VeeValidateErrors',
});

const dictionary = {
    en: {
        messages:{
            is: (fieldName: string, params: any[], data?: any) => {
                if (fieldName === 'confirm password') {
                    return 'The password confirmation does not match'
                }
                return `The ${fieldName} value is not valid`
            }
        }
    }
};
Validator.localize(dictionary);

Validator.extend('checkPassword', {
    getMessage: field => 'Password should have minimum eight characters, at least one uppercase letter (latin or cyrillic), one lowercase letter (latin or cyrillic), one number and one special character',
    validate: value => /^(?=.*[0-9])(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ])(?=.*[!@#$%^&+=\-*?\][{}()<>/_.,;|'"])(?=\S+$).{8,128}$/.test(value),
});

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueNotification)
Vue.use(VModal)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(UtilsVuePlugin)
Vue.use(ConstantsVuePlugin)

Vue.use(FormDatepickerPlugin)

Vue.use(VueSignaturePad)

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
    if (error.response && ((error.response.data && error.response.data.message === "Token expired") || Boolean(error.response.headers['token-expired']))) {
        return AuthService.refreshToken()
            .then(() => {
                const config = error.config;
                config.headers.Authorization = authorization();
                return new Promise((resolve, reject) => {
                    axios.request(config).then(response => {
                        resolve(response);
                    }).catch((error) => {
                        reject(error);
                    })
                });

            })
    }
    store.dispatch("Auth/signOut");
    router.push("/login");
    return new Promise(
        (resolve, reject) => {
            reject(error);
        }
    );
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
