














































import {Component, Vue} from "vue-property-decorator";
import {SickLeavePolicy, SickLeavePolicyFilter} from "@/dto/payroll/policies/SickLeavePolicyDTO";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import SickLeavePolicyCreationModal from "@/components/payroll/policies/sickLeave/SickLeaveCreationModal.vue";
import SickLeaveEditModal from "@/components/payroll/policies/sickLeave/SickLeaveEditModal.vue";
import moment from "moment";
import {Moment} from "moment-timezone";
import {PolicySignature, PTOPolicy} from "@/dto/payroll/policies/PTOPolicyDTO";
import PolicySignatureOptions from "@/components/payroll/policies/PolicySignatureOptions.vue";
import {prettyEnum} from "../../../../utils/StringUtils";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import RouteNames from "@/router/RouteNames";
import PolicyService from "@/services/payroll/PolicyService";

@Component({
	components: {SingleFileHolder},
	methods: {
		prettyEnum,
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	}
})
export default class SickLeavePolicyList extends Vue {

	private policies: Array<SickLeavePolicy> = [];
	private totalPages = 0;

	private filter = new SickLeavePolicyFilter(
		{
			companyId: this.$wss.getCurrent.id
		}
	)

	public created() {
		this.loadSickLeavePolicies();
	}

	private loadSickLeavePolicies() {
		Application.startLoading();
		PolicyService.getPageOfSickLeavePolicies(this.filter).then(
			res => {
				this.policies = res.data.data;
				this.totalPages = res.data.countOfPages;
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private disable(sickLeavePolicy: SickLeavePolicy) {
		this.$modal.show(
			DeleteConfirmation,
			{
				onDelete: () => this.disablePolicy(sickLeavePolicy.id),
				targetName: sickLeavePolicy.title,
				operationName: "disable"
			}
		)
	}

	private disablePolicy(id: number) {
		PolicyService.disableSickLeave(id).then(
			() => this.loadSickLeavePolicies(),
			err => processError(err, this)
		)
	}

	private create() {
		this.$modal.show(
			SickLeavePolicyCreationModal,
			{
				callback: () => this.loadSickLeavePolicies()
			},
			{
				width: "50%",
			}
		)
	}

	private edit(sickLeavePolicy: SickLeavePolicy) {
		this.$modal.show(
			SickLeaveEditModal,
			{
				policy: sickLeavePolicy,
				callback: () => this.loadSickLeavePolicies()
			},
			{
				width: "50%",
			}
		)
	}

	private showOptions(signature: PolicySignature) {
		this.$modal.show(
			PolicySignatureOptions,
			{
				signature: signature,
				callback: () => this.loadSickLeavePolicies(),
				mode: "SLP"
			}
		)
	}

	private toSignPage(policy: SickLeavePolicy) {
		this.$router.push({name: RouteNames.SIGN_PUBLIC, params: {publicId: policy.publicId!}})
	}
}

