import {IEmployment} from "@/dto/payroll/IEmployment";

export class VacationFilter {
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    employmentsId: Array<number> = [];
    employerId: number | null = null
    page = 0;
    size = 20;

    constructor(init?: Partial<VacationFilter>) {
        Object.assign(this, init)
    }
}

export class VacationDto {
    id!: number
    startDate!: Date
    endDate!: Date
    startTime!: Date
    endTime!: Date
    paid!: boolean
    employment!: IEmployment
    startDateTime!: string
    endDateTime!: string
}

export class CreateVacationPayload {
    startDate: Date | null = null;
    endDate: Date | null = null;
    startTime: Date | string | null = null;
    endTime: Date | string | null = null;
    employmentId: number | null = null;
    isPaid = false

    constructor(init?: Partial<CreateVacationPayload>) {
        Object.assign(this, init)
    }
}

export class VacationBalance {
    id!: number
    balance!: number
    carryover!: number
    totalBalanceHours!: number
    totalBalanceDays!: number
}

export class UpdateVacationCarryoverPayload {
    employmentId: number | null = null;
    carryover: number | null = null;

    constructor(init?: Partial<UpdateVacationCarryoverPayload>) {
        Object.assign(this, init)
    }
}