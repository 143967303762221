











import {Component, Prop, Vue} from "vue-property-decorator";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

@Component
export default class GeneralReportControlPanelModal extends Vue {

  @Prop()
  private reportFile!: FileMetaDTO


  @Prop({default: function () {return;}})
  private callBack!: () => void


  private download() {
    this.$docUtils.download(this.reportFile.id)
    this.$modal.hideAll()
  }

}

