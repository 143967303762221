import {EmploymentType} from "@/constants/EmploymentType";
import CompanyDTO from "@/dto/company/CompanyDTO";
import {PolicySignature, PTOPolicy} from "@/dto/payroll/policies/PTOPolicyDTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export class CreateSickLeavePolicyPayload {
    companyId: number | null = null;
    payout = false;
    total: number | null = null;
    title = "Sick Leave Policy (NY)";
    carryover: number | null = null;
    growthRate: number | null = null;
    applicableFor: EmploymentType | null = null;
    applicableState: string | null = "NY"

    constructor(init?: Partial<CreateSickLeavePolicyPayload>) {
        Object.assign(this, init)
    }
}

export class UpdateSickLeavePolicyPayload {
    policyId: number | null = null;
    payout: boolean | null = null;
    total: number | null = null;
    title: string | null = null;
    carryover: number | null = null;
    growthRate: number | null = null;
    applicableFor: EmploymentType | null = null;
    applicableState: string | null = "NY"

    constructor(init?: Partial<UpdateSickLeavePolicyPayload>) {
        Object.assign(this, init)
    }
}

export class SickLeavePolicy {
    id!: number;
    company!: CompanyDTO;
    payout!: boolean;
    totalHours!: number;
    totalDays!: number;
    carryoverHours!: number;
    carryoverDays!: number;
    title!: string;
    growthRate!: number;
    applicableFor: EmploymentType | null = null;
    applicableState: string | null = null;
    active!: boolean
    createDate!: Date;
    signatures: Array<PolicySignature> = []
    publicId?: string;
    approvedPolicy!: FileMetaDTO
}

export class SLPSignature {
    id!: number
    employment!: IEmployment;
    policy!: SickLeavePolicy;
    document?: FileMetaDTO;
    publicId?: string
}

export class SickLeavePolicyFilter {
    companyId: number | null = null;
    applicableFor: EmploymentType | null = null;
    applicableState: string | null = null;
    isActive: boolean | null = null;
    signed: boolean | null = null;
    pageNumber = 0;
    pageSize = 20

    constructor(init?: Partial<SickLeavePolicyFilter>) {
        Object.assign(this, init)
    }
}