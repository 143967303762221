import Vue from 'vue'
import Component from 'vue-class-component'
import {v4 as uuidv4} from 'uuid';

@Component
export default class GetRandomId extends Vue {

  getRandomId(prefix: unknown | null = null): string {
    return prefix ? `${prefix}-${uuidv4()}` : uuidv4();
  }

}
