



























































import {Component, Vue} from "vue-property-decorator";
import PortalDate from "@/components/common/PortalDate.vue";
import RouteNames from "@/router/RouteNames";
import Application from "@/state/Application";
import CompanyService from "@/services/CompanyService";
import {processError} from "@/utils/ComponentUtils";
import EmploymentService from "@/services/EmploymentService";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {EmployeeFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import CompanyDTO from "@/dto/company/CompanyDTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import SelectOption from "@/components/common/SelectOption";
import {
	PayrollReport,
	PayrollReportFilter,
	REPORT_TYPE_OPTIONS,
} from "@/components/payroll/business/employments/employee/PayrollReport";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PayrollService from "@/services/payroll/PayrollService";
import ReportView from "@/components/payroll/business/employments/employee/ReportView.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";

@Component({
	computed: {
		REPORT_TYPE_OPTIONS() {
			return REPORT_TYPE_OPTIONS
		}
	},
	components: {PaginationComponent, ReportView, PortalSelect, PortalDate}
})
export default class PayrollReportList extends Vue {
	private totalPages = 0;
	private reports: Array<PayrollReport> = [];
	private company: CompanyDTO | null = null;
	private employments: Array<IEmployment> = [];
	private filter: PayrollReportFilter = new PayrollReportFilter(
		{
			employerIds: [this.$wss.getCurrent.id]
		}
	)

	private toPayrollReportBuilder() {
		this.$router.push({
			name: RouteNames.PAYROLL_REPORTS_BUILDER,
			params: {
				companyId: this.$wss.getCurrent.id.toString()
			}
		})
	}

	public created(): void {
		Application.startLoading();
		Promise.all(
			[
				this.loadCompany(),
				this.loadEmployments(),
				this.loadReports()
			]
		)
			.then(() => Application.stopLoading())
	}

	private async loadCompany() {
		return CompanyService.getById(this.$wss.getCurrentIdOrNull!).then(
			res => this.company = res.data,
			err => processError(err, this)
		)
	}

	private async loadEmployments() {
		return EmploymentService.getPageByFilter<EmployeeDto>(
			new EmployeeFilter(
				{
					status: [EmploymentStatus.NEW, EmploymentStatus.PENDING_FOR_DOCUMENTS, EmploymentStatus.ACTIVE],
					employerId: this.$wss.getCurrent.id,
					pageSize: 100,
				}
			)
		).then(
			res => this.employments = res.data.data,
			err => processError(err, this)
		)
	}

	get employmentOptions(): Array<SelectOption> {
		return this.employments.map(it => SelectOption.builder().title(it.details.name).value(it.id).build())
	}

	private resetFilter() {
		this.filter = new PayrollReportFilter(
			{
				employerIds: [this.$wss.getCurrent.id]
			}
		)
	}

	private async loadReports() {
		Application.startLoading();
		return PayrollService.getPageOfReportsByFilter(this.filter).then(
			res => {
				this.reports = res.data.data;
				this.totalPages = res.data.countOfPages;
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private changePage(page: number) {
		this.filter.page = page - 1;
		this.loadReports();
	}

}

