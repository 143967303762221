




























































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import PortalSelect from "@/components/common/PortalSelect.vue";
import {PAY_PERIOD_TYPE_OPTIONS} from "@/constants/TarifficationConstants";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CompanyService from "@/services/CompanyService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import SelectOption from "@/components/common/SelectOption";
import AddressDTO from "@/dto/AddressDTO";
import BranchDto from "@/components/profile/branches/BranchDtos";
import PortalDate from "@/components/common/PortalDate.vue";
import {
	CreatePayrollFormPayload,
	NewPayrollFilter,
	NewPayrollPeriodDetails,
	PayrollDetailsDTO
} from "@/dto/payroll/PayrollDTO";
import PayrollService from "@/services/payroll/PayrollService";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import {prettyEnum} from "@/utils/StringUtils";
import Notifications from "@/state/Notifications";
import {IEmployment} from "@/dto/payroll/IEmployment";
import RouteNames from "@/router/RouteNames";

@Component({
	methods: {prettyEnum},
	computed: {
		PAY_PERIOD_TYPE_OPTIONS() {
			return PAY_PERIOD_TYPE_OPTIONS
		}
	},
	components: {BTPortalCollapse, PortalCheckbox, PortalInput, PortalDate, PortalSelect}
})
export default class PayrollCloser extends Vue {

	private company: CompanyDTO | null = null

	private periodDetails: Array<NewPayrollPeriodDetails> = [];

	private payrollDetailsForApprove: Array<PayrollDetailsDTO> = []

	private filter: NewPayrollFilter = new NewPayrollFilter(
		{
			employerIds: [this.$wss.getCurrent.id]
		}
	)


	private applyChange() {
		ifValid(this, () => this.loadApplicableEmploymentsForFilter())
	}

	private loadApplicableEmploymentsForFilter() {
		Application.startLoading()
		PayrollService.loadApplicableEmploymentsForFilter(this.filter).then(
			res => {
				this.periodDetails = res.data
				this.periodDetails.forEach(it => it.isSelected = true)
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private async loadCompany() {
		return CompanyService.getById(this.$wss.getCurrentIdOrNull!).then(
			res => this.company = res.data,
			err => processError(err, this)
		)
	}

	get branchOptions(): SelectOption[] {
		const branches: Array<BranchDto> = [];
		if (this.company) {
			if (this.company.headquarter) {
				branches.push(this.company.headquarter)
			}
			if (this.company.branches != null) {
				branches.push(...this.company.branches)
			}
		}
		return branches.map(it => SelectOption.builder().title(it.title).value(it.id).build())
	}

	get workplacesOptions(): SelectOption[] {
		let workplaces: Array<AddressDTO> = [];
		const branches: Array<BranchDto> = [];
		if (this.company) {
			if (this.company.headquarter) {
				branches.push(this.company.headquarter)
			}
			if (this.company.branches != null) {
				branches.push(...this.company.branches)
			}
		}
		branches.forEach(branch => workplaces.push(...this.assembleAddresses(branch)))
		return workplaces.map(place => SelectOption.builder().title(place.fullAddress).value(place.id).build());
	}

	private assembleAddresses(branch: BranchDto) {
		let res: Array<AddressDTO> = [];
		if (branch.legalAddress && branch.legalAddress.fullAddress) {
			res.push(branch.legalAddress)
		}
		if (branch.actualAddress && branch.actualAddress.fullAddress) {
			res.push(branch.actualAddress)
		}
		if (branch.mailingAddress && branch.mailingAddress.fullAddress) {
			res.push(branch.mailingAddress)
		}
		if (branch.registrationAgentAddress && branch.registrationAgentAddress.fullAddress) {
			res.push(branch.registrationAgentAddress)
		}
		return res;
	}

	public created(): void {
		Application.startLoading()
		this.loadCompany()
			.then(() => Application.stopLoading())
	}

	private toApprove(): void {
		const reqs: Array<CreatePayrollFormPayload> = this.buildCreateReqs()
		Application.startLoading()
		PayrollService.getPreviewPayroll(reqs).then(
			res => {
				this.payrollDetailsForApprove = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)

	}

	private buildCreateReqs(): Array<CreatePayrollFormPayload> {
		return this.periodDetails
			.filter(it => it.isSelected)
			.map(
				it => new CreatePayrollFormPayload(
					{
						payPeriodStart: it.start,
						payPeriodEnd: it.end,
						payDate: it.payDate,
						employmentId: it.employment.id,
						regularHours: Number(it.regularHours.toString().replaceAll(",", ".")),
						overtimeHours: Number(it.overtimeHours.toString().replaceAll(",", ".")),
						doubleTimeHours: it.doubleTimeHours ? Number(it.doubleTimeHours.toString().replaceAll(",", ".")) : 0,
						holidayHours: it.holidayHours ? Number(it.holidayHours.toString().replaceAll(",", ".")) : 0,
						ptoHours: it.ptoHours ? Number(it.ptoHours.toString().replaceAll(",", ".")) : 0,
						sickLeaveHours: it.sickLeaveHours ? Number(it.sickLeaveHours.toString().replaceAll(",", ".")) : 0,
						bonus: it.bonus ? Number(it.bonus.toString().replaceAll(",", ".")) : 0,
						owedTips: it.owedTips ? Number(it.owedTips.toString().replaceAll(",", ".")) : 0,
						paidTips: it.paidTips ? Number(it.paidTips.toString().replaceAll(",", ".")) : 0,
						commission: it.commission ? Number(it.commission.toString().replaceAll(",", ".")) : 0,
						expenseReimburse: it.expenseReimburse ? Number(it.expenseReimburse.toString().replaceAll(",", ".")) : 0
					}
				)
			)
	}

	save(): void {
		const reqs: Array<CreatePayrollFormPayload> = this.buildCreateReqs()
		Application.startLoading()
		PayrollService.createPayrollDetails(reqs).then(
			res => {
				Notifications.success("The New Payroll has been successfully created!")
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private buildRoutTransition(employment: IEmployment): { name: string, params: any} {
		return {
			name: RouteNames.PAYROLL_COMPANY_EMPLOYEE_DETAILS,
			params: {
				companyId: employment.employer!.id,
				employeeId: employment.id
			}
		};
	}

}

