








































import { Component, Prop, Vue } from "vue-property-decorator";
import VueSignaturePad from "vue-signature-pad";
import { namespace } from "vuex-class";
import { LabelType } from "@/constants/SignatureConstants";
import LabelDTO from "@/dto/signature/LabelDTO";
import { EventBus } from "@/utils/EventBus";

const AppModule = namespace("App");
const SignatureModule = namespace("Signature");

@Component({
  computed: {
    LabelType() {
      return LabelType;
    }
  }
})
export default class PortalSignaturePad extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @SignatureModule.Mutation
  private addSignature!: (id: number, url: string) => void;

  @Prop({ default: '100%' })
  width!: string;

  @Prop({ default: '100%' })
  height!: string;

  @Prop()
  label!: LabelDTO;

  isEmptySignature = true;
  signature = new FormData();

  $refs!: {
    signaturePad: VueSignaturePad
  }

  save(): void {
    const { data } = this.$refs.signaturePad.saveSignature();
    EventBus.$emit('sign', this.label.id, data!);
    this.$emit('close');
  }

  clear(): void {
    this.$refs.signaturePad.clearSignature();
    this.onEnd();
  }

  onEnd(): void {
    this.isEmptySignature = this.$refs.signaturePad?.isEmpty();
  }

  get publicId(): string {
    return this.$route.params.publicId;
  }

}
