

































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {
	CreateGeneralPayrollReportTask, GeneralPayrollReport, GROUP_MODE_OPTIONS,
	SOURCE_TYPE_OPTIONS
} from "@/components/payroll/business/employments/employee/PayrollReport";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CompanyService from "@/services/CompanyService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import EmploymentService from "@/services/EmploymentService";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {EmployeeFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import SelectOption from "@/components/common/SelectOption";
import BranchDto from "@/components/profile/branches/BranchDtos";
import AddressDTO from "@/dto/AddressDTO";
import PayrollService from "@/services/payroll/PayrollService";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import RouteNames from "@/router/RouteNames";

@Component({
	computed: {
		GROUP_MODE_OPTIONS() {
			return GROUP_MODE_OPTIONS
		},
		SOURCE_TYPE_OPTIONS() {
			return SOURCE_TYPE_OPTIONS
		}
	},
	components: {BTPortalCollapse, PortalDate, PortalSelect}
})
export default class PayrollReportBuilder extends Vue {

	private company: CompanyDTO | null = null

	private previewGeneralReport: GeneralPayrollReport | null = null

	private employments: Array<EmployeeDto> = []

	private task = new CreateGeneralPayrollReportTask(
		{
			employerIds: [this.$wss.getCurrent.id]
		}
	)

	get employmentOptions(): Array<SelectOption> {
		return this.employments.map(it => SelectOption.builder().title(it.details.name).value(it.id).build())
	}

	public created(): void {
		Application.startLoading();
		Promise.all(
			[
				this.loadCompany(),
				this.loadEmployments()
			]
		)
			.then(() => Application.stopLoading())
	}

	private async loadCompany() {
		return CompanyService.getById(this.$wss.getCurrentIdOrNull!).then(
			res => this.company = res.data,
			err => processError(err, this)
		)
	}

	private async loadEmployments() {
		return EmploymentService.getPageByFilter<EmployeeDto>(
			new EmployeeFilter(
				{
					status: [EmploymentStatus.NEW, EmploymentStatus.PENDING_FOR_DOCUMENTS, EmploymentStatus.ACTIVE],
					employerId: this.$wss.getCurrent.id,
					pageSize: 100,
				}
			)
		).then(
			res => this.employments = res.data.data,
			err => processError(err, this)
		)
	}

	get branchOptions(): SelectOption[] {
		const branches: Array<BranchDto> = [];
		if (this.company) {
			if (this.company.headquarter) {
				branches.push(this.company.headquarter)
			}
			if (this.company.branches != null) {
				branches.push(...this.company.branches)
			}
		}
		return branches.map(it => SelectOption.builder().title(it.title).value(it.id).build())
	}

	get workplacesOptions(): SelectOption[] {
		let workplaces: Array<AddressDTO> = [];
		const branches: Array<BranchDto> = [];
		if (this.company) {
			if (this.company.headquarter) {
				branches.push(this.company.headquarter)
			}
			if (this.company.branches != null) {
				branches.push(...this.company.branches)
			}
		}
		branches.forEach(branch => workplaces.push(...this.assembleAddresses(branch)))
		return workplaces.map(place => SelectOption.builder().title(place.fullAddress).value(place.id).build());
	}

	private assembleAddresses(branch: BranchDto) {
		let res: Array<AddressDTO> = [];
		if (branch.legalAddress && branch.legalAddress.fullAddress) {
			res.push(branch.legalAddress)
		}
		if (branch.actualAddress && branch.actualAddress.fullAddress) {
			res.push(branch.actualAddress)
		}
		if (branch.mailingAddress && branch.mailingAddress.fullAddress) {
			res.push(branch.mailingAddress)
		}
		if (branch.registrationAgentAddress && branch.registrationAgentAddress.fullAddress) {
			res.push(branch.registrationAgentAddress)
		}
		return res;
	}

	private preview() {
		ifValid(this, () => {
			Application.startLoading()
			PayrollService.buildPreviewGeneralReport(this.task).then(
				res => {
					this.previewGeneralReport = res.data
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		})
	}

	private create() {
		ifValid(this, () => {
			Application.startLoading()
			PayrollService.createGeneralReport(this.task).then(
				() => {
					Application.stopLoading()
					this.$router.push(
						{
							name: RouteNames.PAYROLL_REPORTS,
							params: {
								companyId: this.$wss.getCurrent.id.toString()
							}
						}
					)
				},
				err => processError(err, this)
			)
		})
	}
}

