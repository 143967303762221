


























































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {GeneralPayrollReport} from "@/components/payroll/business/employments/employee/PayrollReport";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import GeneralReportControlPanelModal
	from "@/components/payroll/business/employments/employee/GeneralReportControlPanelModal.vue";

@Component({
	components: {BTPortalCollapse}
})
export default class GeneralPayrollReportView extends Vue {

	@Prop()
	private report!: GeneralPayrollReport

	@Prop({default: true})
	private showControlButton!: boolean

	private download() {
		this.$docUtils.download(this.report.fileMeta.id)
	}

	private showControlPanel() {
		this.$modal.show(
			GeneralReportControlPanelModal,
			{
				reportFile: this.report.fileMeta,
				callBack: this.$modal.hideAll
			}
		)
	}

}

