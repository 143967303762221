










import {Component, Prop, Vue} from "vue-property-decorator";
import {SimplePayrollReport} from "@/components/payroll/business/employments/employee/PayrollReport";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import {prettyEnum} from "@/utils/StringUtils";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";

@Component
export default class PayrollReportControlPanelModal extends Vue {

	@Prop()
	private report!: SimplePayrollReport

	@Prop({default: function () {return;}})
	private callBack!: () => void

	private download() {
		this.$docUtils.download(this.report.fileMeta.id)
		this.$modal.hideAll()
	}

	private voidPayroll() {
		Application.startLoading()
		PayrollService.voidPayroll(this.report.payrollId).then(
			() => {
				this.$modal.hideAll();
				console.log(this.callBack)
				this.callBack();
				console.log("fmrkfmrk")
				Application.stopLoading();
			},
			err => processError(err, this)
		)
	}

	private void() {
		console.log(this.report)
		this.$modal.show(
			DeleteConfirmation,
			{
				targetName: prettyEnum(this.report.reportType),
				operationName: "void",
				onDelete: () => this.voidPayroll()
			}
		)
	}

}

