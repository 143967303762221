import {CounterpartyType} from "@/constants/CounterpartyType";
import {EmploymentType} from "@/constants/EmploymentType";
import PersonDTO from "@/dto/person/PersonDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import {Account} from "@/dto/Account";
import {IEmployment, ProfileRoute} from "@/dto/payroll/IEmployment";
import BranchDto from "@/components/profile/branches/BranchDtos";
import {ProjectDTO} from "@/dto/payroll/ProjectDTOs";
import RouteNames from "@/router/RouteNames";

export interface IContractor extends IEmployment{
    counterpartyType: CounterpartyType | null,
}
export class BusinessContractorDTO implements IContractor, UpdateBusinessContractorDetailsPayload {
    counterpartyType: CounterpartyType | null = null;
    employer: CompanyDTO | null = null;
    employmentStart: Date | null = null;
    employmentTermination: Date | null = null;
    employmentType: EmploymentType | null = null;
    id: number | null = null;
    details: CompanyDTO = new CompanyDTO();
    status: EmploymentStatus | null = null;
    projects: ProjectDTO[] = [];

    route: ProfileRoute;

    constructor(init? : Partial<BusinessContractorDTO>) {
        Object.assign(this, init)
        this.route = {
            name: RouteNames.PAYROLL_COMPANY_BUSINESS_CONTRACTOR,
            params: {
                contractorId: this.id
            }
        };
    }
}

export class IndividualContractorDTO implements IContractor, UpdateIndividualContractorDetailsPayload {
    counterpartyType: CounterpartyType | null = null;
    employer: CompanyDTO | null = null;
    employmentStart: Date | null = null;
    employmentTermination: Date | null = null;
    employmentType: EmploymentType | null = null;
    id: number | null = null;
    details: PersonDTO = new PersonDTO();
    status: EmploymentStatus | null = null;
    branches: Array<BranchDto> = [];
    projects: ProjectDTO[] = [];

    route: ProfileRoute = {
        name: RouteNames.PAYROLL_COMPANY_INDIVIDUAL_CONTRACTOR,
        params: {
            contractorId: this.id
        }
    };

    constructor(init? : Partial<IndividualContractorDTO>) {
        Object.assign(this, init)
    }

}

export interface IUpdateContractorPayload {
    id: number | null,
    employmentStart: Date | null
}

export interface UpdateBusinessContractorDetailsPayload extends IUpdateContractorPayload {
    id: number | null;
    employmentStart: Date | null;
}

export interface UpdateIndividualContractorDetailsPayload extends IUpdateContractorPayload {
    id: number | null;
    employmentStart: Date | null;
    branches: Array<BranchDto>;
}

export enum EmploymentStatus {
    NEW = "NEW",
    PENDING_FOR_DOCUMENTS = "PENDING_FOR_DOCUMENTS",
    ACTIVE = "ACTIVE",
    TERMINATED = "TERMINATED"
}