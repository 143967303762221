















































































import {Component, Prop, Vue} from "vue-property-decorator";
import {CreateSickLeavePolicyPayload} from "@/dto/payroll/policies/SickLeavePolicyDTO";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {EMPLOYMENT_TYPE_OPTIONS} from "@/constants/EmploymentType";
import SelectOption from "@/components/common/SelectOption";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import PolicyService from "@/services/payroll/PolicyService";

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		},
		EMPLOYMENT_TYPE_OPTIONS() {
			return EMPLOYMENT_TYPE_OPTIONS
		}
	},
	components: {PortalSelect, PortalInput, PortalCheckbox}
})
export default class SickLeavePolicyCreationModal extends Vue {

	private req = new CreateSickLeavePolicyPayload(
		{
			companyId: this.$wss.getCurrent.id
		}
	)

	@Prop()
	private callback!: () => void

	private create() {
		Application.startLoading();
		PolicyService.createSickLeavePolicy(this.req).then(
			() => {
				this.$modal.hideAll();
				Application.stopLoading();
				this.callback();
			},
			err => processError(err, this)
		)
	}



}

