


























import {Vue, Component, Prop} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import Application from "@/state/Application";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import SelectOption from "@/components/common/SelectOption";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {CreateSickLeavePayload, SickLeaveBalance} from "@/components/payroll/sickLeave/SickLeave";

@Component({
	components: {PortalSelect, PortalCheckbox, PortalDate}
})
export default class SickLeaveCreationModal extends Vue {

	@Prop({default: null})
	private employment!: IEmployment | null

	@Prop({default: null})
	private employments!: Array<IEmployment> | null

	@Prop()
	private callback!: () => void

	private balance: SickLeaveBalance | null = null

	get employmentOptions(): Array<SelectOption> {
		return this.employments!.map(it => SelectOption.builder().title(it.details.name).value(it.id).build())
	}

	private req = new CreateSickLeavePayload(
		{
			employmentId: this.employment ? this.employment.id! : null,
		}
	)

	private loadAvailableBalance() {
		if (this.req.employmentId) {
			Application.startLoading()
			SickLeaveService.getSickLeaveBalance(this.req.employmentId).then(
				res => {
					this.balance = res.data;
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		}
	}

	public created() {
		this.loadAvailableBalance()
	}

	private create() {
		ifValid(this, () => {
			Application.startLoading();
			SickLeaveService.createSickLeaveRecord(this.req).then(
				() => {
					this.$modal.hideAll();
					Application.stopLoading()
					this.callback()
				},
				err => processError(err, this)
			)
		})
	}

}
