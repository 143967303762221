








































import {Component, Prop, Vue} from "vue-property-decorator";
import {SickLeavePolicy, SickLeavePolicyFilter} from "@/dto/payroll/policies/SickLeavePolicyDTO";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import {prettyEnum} from "../../../../utils/StringUtils";
import PolicyService from "@/services/payroll/PolicyService";

@Component({
	methods: {prettyEnum}
})
export default class SickLeaveAttachModal extends Vue {

	@Prop()
	private employmentId!: number

	@Prop()
	private callback!: () => void

	private filter = new SickLeavePolicyFilter(
		{
			companyId: this.$wss.getCurrent.id,
			signed: true,
			isActive: true
		}
	)

	private policies: Array<SickLeavePolicy> = []

	private loadPolicies() {
		Application.startLoading();
		PolicyService.getPageOfSickLeavePolicies(this.filter).then(
			res => {
				this.policies = res.data.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadPolicies()
	}

	private attachPolicy(policy: SickLeavePolicy) {
		Application.startLoading()
		console.log(this.employmentId)
		PolicyService.attachSLP(this.employmentId, policy.id).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading();
				this.callback()
			},
			err => processError(err, this)
		)
	}

}

