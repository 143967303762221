export default class SelectOption {
    public title: string | null = null;

    public value: any | null = null;

    constructor(init?: Partial<SelectOption>) {
        Object.assign(this, init)
    }

    public static builder(): Builder {
        return new Builder();
    }
}

class Builder {
    private selectOption!: SelectOption;

    constructor() {
        this.selectOption = new SelectOption();
    }

    public title(title: string | null): Builder {
        this.selectOption.title = title;
        return this;
    }

    public value(value: any, ): Builder {
        this.selectOption.value = value;
        return this;
    }

    public build(): SelectOption {
        return this.selectOption;
    }
}

export class OptionsBuilder {

    private options : Array<SelectOption> = [];

    public static of(title: string, value: any, condition = true): OptionsBuilder {
        return new OptionsBuilder().of(title, value, condition);
    }

    public of(title: string, value: any, condition = true): OptionsBuilder {
        if(condition){
            this.options.push(new SelectOption({title: title, value: value}));
        }
        return this;
    }

    public get(): Array<SelectOption> {
        return this.options;
    }

}