import {IEmployment} from "@/dto/payroll/IEmployment";
import moment, {Moment} from "moment/moment";

export class TimeSheetRecord{
    id: number | null = null;
    date!: Date;
    workPeriodStart!: Date;
    workPeriodEnd: Date | null = null;
    type!: string;
    employment!: IEmployment;
    readonly duration: string = this.workPeriodStart != undefined ? "0 <" : "-"
    readonly netPay: string = "$0"
    zoneId!: string

    originalStartTime: Date | null = null;
    originalEndTime: Date | null = null;

    constructor(date: Date, type: string, workPeriodStart: Date, employment: IEmployment) {
        this.date = date;
        this.type = type;
        this.workPeriodStart = workPeriodStart
        this.employment = employment
    }
}

export class TimeSheetPayDate {
    date!: Date;
    records: Array<TimeSheetRecord> = []
    readonly duration: string = "-"
    readonly durationInSeconds!: number
    readonly netPay: string = "$0"

    timePoint: Moment

    constructor(date: Date, records: Array<TimeSheetRecord>) {
        this.date = date;
        this.records = records;
        this.timePoint = moment(date)
    }
}

export class TimeSheetPayPeriod {
    employment!: IEmployment;
    payDates: Array<TimeSheetPayDate> = [];
    openRecord?: TimeSheetRecord
    readonly duration: string = "0:0"
    readonly netPay: string = "$0"
}

export class UpdateTimeSheetRecordPayload {
    workPeriodStart: string | null = null
    workPeriodEnd: string | null = null
    id: number | null = null
    employmentId: number | null = null;

    constructor(record: TimeSheetRecord) {
        this.workPeriodStart = moment(record.originalStartTime ? record.originalStartTime : record.workPeriodStart).format("YYYY-MM-DD[T]HH:mm")
        this.workPeriodEnd = (record.originalEndTime ? record.originalEndTime : record.workPeriodEnd) ? moment(record.originalEndTime ? record.originalEndTime
                : record.workPeriodEnd).format("YYYY-MM-DD[T]HH:mm") : null
        this.id = record.id
        this.employmentId = record.employment.id
    }
}

export class CreateTimeSheetRecordPayload {
    workPeriodStart: string | null = null
    workPeriodEnd: string | null = null
    date: Date | null = null
    employmentId: number | null = null;

    constructor(record: TimeSheetRecord) {
        this.workPeriodStart = moment(record.workPeriodStart).format("YYYY-MM-DD[T]HH:mm")
        this.workPeriodEnd = record.workPeriodEnd ? moment(record.workPeriodEnd).format("YYYY-MM-DD[T]HH:mm") : null
        this.date = record.date
        this.employmentId = record.employment.id
    }
}

export class TimeSheetFilter {
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    date: Date | null = null;
    employmentsId: Array<number> = [];
    timeZone: string | null = null;

    constructor(init?: Partial<TimeSheetFilter>) {
        Object.assign(this, init)
        init?.employmentsId?.forEach(it => this.employmentsId.push(it))
    }
}
