import {IEmployment, ProfileRoute} from "@/dto/payroll/IEmployment";
import {CounterpartyType} from "@/constants/CounterpartyType";
import CompanyDTO from "@/dto/company/CompanyDTO";
import {EmploymentType} from "@/constants/EmploymentType";
import PersonDTO from "@/dto/person/PersonDTO";
import {
    EmploymentStatus,
} from "@/components/payroll/business/employments/contractors/Contractor";
import {ProjectDTO} from "@/dto/payroll/ProjectDTOs";
import Tariffication from "@/components/payroll/business/employments/employee/Tariffication.vue";
import TarifficationDto from "@/dto/payroll/Tariffication";
import RouteNames from "@/router/RouteNames";

export interface IForeignContractor extends IEmployment{
    counterpartyType: CounterpartyType | null,
}

export class BusinessForeignContractorDTO implements IForeignContractor, UpdateBusinessForeignContractorDetailsPayload {
    counterpartyType: CounterpartyType | null = null;
    employer: CompanyDTO | null = null;
    employmentStart: Date | null = null;
    employmentTermination: Date | null = null;
    employmentType: EmploymentType | null = null;
    id: number | null = null;
    details: CompanyDTO = new CompanyDTO();
    status: EmploymentStatus | null = null;
    projects: ProjectDTO[] = [];

    route: ProfileRoute;

    constructor(init?: Partial<BusinessForeignContractorDTO>) {
        Object.assign(this, init)
        this.route = {
            name: RouteNames.PAYROLL_COMPANY_BUSINESS_FOREIGN_CONTRACTOR,
            params: {
                contractorId: this.id
            }
        };
    }
}

export class IndividualForeignContractorDTO implements IForeignContractor, UpdateIndividualForeignContractorDetailsPayload {
    counterpartyType: CounterpartyType | null = null;
    employer: CompanyDTO | null = null;
    employmentStart: Date | null = null;
    employmentTermination: Date | null = null;
    employmentType: EmploymentType | null = null;
    id: number | null = null;
    details: PersonDTO = new PersonDTO();
    status: EmploymentStatus | null = null;
    projects: ProjectDTO[] = [];
    tariffication!: TarifficationDto

    route: ProfileRoute;

    constructor(init?: Partial<IndividualForeignContractorDTO>) {
        Object.assign(this, init)
        this.route = {
            name: RouteNames.PAYROLL_COMPANY_INDIVIDUAL_FOREIGN_CONTRACTOR,
            params: {
                contractorId: this.id
            }
        };
    }
}

export interface IUpdateForeignContractorPayload {
    id: number | null,
    employmentStart: Date | null
}

export interface UpdateBusinessForeignContractorDetailsPayload extends IUpdateForeignContractorPayload {
    id: number | null;
    employmentStart: Date | null;
}

export interface UpdateIndividualForeignContractorDetailsPayload extends IUpdateForeignContractorPayload {
    id: number | null;
    employmentStart: Date | null;
}
