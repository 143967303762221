


































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import moment from 'moment';
import RouteNames from "@/router/RouteNames";
import Workspace, { WorkspaceType } from "@/dto/auth/Workspace";
import { namespace } from "vuex-class";
import NotificationDTO from "@/dto/NotificationDTO";
import session from "@/store/modules/session.module";
import { getCustomerUUID } from "@/services/auth-header";
import Workspaces from "@/state/Workspaces";

const Auth = namespace("Auth");

@Component({
    computed: {
        WorkspaceType() {
            return WorkspaceType
        }
    }
})
export default class NotificationHolder extends Vue{
    private isOpen = false;

    private countUnseenMess = 0;

    private notifications: Array<NotificationDTO> = [];

    @Auth.Getter
    private isLoggedIn!: boolean;

    private moment = moment;

	public toggle(): void {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.countUnseenMess = 0;
        }
    }

	private getSenderId(): string {
		return Workspaces.getCurrent ? Workspaces.getCurrent.userId.toString() : getCustomerUUID();
	}

    public mounted(): void {
        session.subscribe(
            {
                destination: `/portal/customer/${this.getSenderId()}/notification`,
                onReceiveMessageMethod: this.onReceivedNotification
            }
        )
    }

    private onReceivedNotification(obj: any) {
        let notification = JSON.parse(obj.body) as NotificationDTO;
        this.notifications = [notification, ...this.notifications];
        if (!this.isOpen) {
            this.countUnseenMess += 1;
        }
    }

    toAccountSettings() {
        this.isOpen = false;
        this.$router.push({
            name: RouteNames.PROFILE_ACCOUNT,
            params: {
                type: Workspaces.getCurrent.getPathElement,
                id: `${Workspaces.getCurrent.id}`
            }
        });
    }
};
