























import {Component, Prop, Vue} from "vue-property-decorator";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import {SickLeavePolicy, SLPSignature} from "@/dto/payroll/policies/SickLeavePolicyDTO";
import SickLeaveAttachModal from "@/components/payroll/policies/sickLeave/SickLeaveAttachModal.vue";
import EmbeddedFile from "@/components/common/EmbeddedFile.vue";
import RouteNames from "@/router/RouteNames";
import PolicyService from "@/services/payroll/PolicyService";

@Component({
	components: {EmbeddedFile}
})
export default class SLPView extends Vue {

	@Prop()
	private employmentId!: number | null

	private signature: SLPSignature | null = null;

	private loadSLPByEmploymentId() {
		Application.startLoading()
		PolicyService.getSickLeavePolicyByEmploymentId(this.employmentId!).then(
			res => {
				this.signature = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadSLPByEmploymentId()
	}

	private openAttachModal() {
		this.$modal.show(
			SickLeaveAttachModal,
			{
				employmentId: this.employmentId,
				callback: () => this.loadSLPByEmploymentId()
			}
		)
	}

	private toSignPage() {
		this.$router.push({name: RouteNames.SIGN_PUBLIC, params: {publicId: this.signature!.publicId!}})
	}
}

