export default class FileMetaDTO {

    constructor(id: number, fileName: string, extension: string | null, createDate: Date, internalKey: string) {
        this.id = id;
        this.fileName = fileName;
        this.extension = extension;
        this.createDate = createDate;
        this.internalKey = internalKey;
    }

    public id: number

    public fileName: string

    public extension: string | null

    public createDate!: Date;

    public internalKey!: string;

}
