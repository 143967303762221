import {
    CreateVacationPayload,
    UpdateVacationCarryoverPayload,
    VacationBalance,
    VacationDto,
    VacationFilter
} from "@/dto/payroll/VacationDTO";
import QueryUtils from "@/utils/QueryUtils";
import axios from "axios";
import Pageable from "@/dto/Pageable";
import {PAYROLL_VACATION_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";

class VacationService {
    getPageOfVacations(filter: VacationFilter) {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<VacationDto>>(`${PAYROLL_VACATION_ENDPOINT}${query}`, {headers: authHeader()})
    }

    getVacationBalance(employmentId: number) {
        return axios.get<VacationBalance>(`${PAYROLL_VACATION_ENDPOINT}/balance?employmentId=${employmentId}`, {headers: authHeader()})
    }

    createVacationRecord(req: CreateVacationPayload) {
        return axios.post<void>(`${PAYROLL_VACATION_ENDPOINT}`, req, {headers: authHeader()})
    }

    deleteVacationRecord(vacationId: number, employmentId: number) {
        return axios.delete(`${PAYROLL_VACATION_ENDPOINT}?vacationId=${vacationId}&employmentId=${employmentId}`, {headers: authHeader()})
    }

    updateVacationBalance(payload: UpdateVacationCarryoverPayload) {
        return axios.put(`${PAYROLL_VACATION_ENDPOINT}/balance`, payload, {headers: authHeader()})
    }
}

export default new VacationService()