















































































import {Component, Prop, Vue} from "vue-property-decorator";
import {SickLeavePolicy, UpdateSickLeavePolicyPayload} from "@/dto/payroll/policies/SickLeavePolicyDTO";
import {EMPLOYMENT_TYPE_OPTIONS} from "@/constants/EmploymentType";
import SelectOption from "@/components/common/SelectOption";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PolicyService from "@/services/payroll/PolicyService";

@Component({
	components: {PortalSelect, PortalCheckbox, PortalInput},
	computed: {
		EMPLOYMENT_TYPE_OPTIONS() {
			return EMPLOYMENT_TYPE_OPTIONS
		},
		SelectOption() {
			return SelectOption
		}
	}
})
export default class SickLeaveEditModal extends Vue {

	@Prop()
	private policy!: SickLeavePolicy

	@Prop()
	private callback!: () => void

	private req = new UpdateSickLeavePolicyPayload(
		{
			policyId: this.policy.id,
			payout: this.policy.payout,
			total: this.policy.totalHours,
			title:  this.policy.title,
			carryover: this.policy.carryoverHours,
			growthRate: this.policy.growthRate,
			applicableFor: this.policy.applicableFor,
			applicableState: this.policy.applicableState
		}
	)

	private edit() {
		Application.startLoading();
		PolicyService.updateSickLeavePolicy(this.req).then(
			() => {
				this.$modal.hideAll();
				Application.stopLoading();
				this.callback();
			},
			err => processError(err, this)
		)
	}

}

