











































































































import PortalInput from "@/components/common/PortalInput.vue";
import {CreatePTOPolicyPayload} from "@/dto/payroll/policies/PTOPolicyDTO";
import {Component, Prop, Vue} from "vue-property-decorator";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import Portal from "portal-vue/src/components/portal";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {EMPLOYMENT_TYPE_OPTIONS} from "@/constants/EmploymentType";
import {STATE_LIST} from "@/constants/ProfileConstants";
import SelectOption from "@/components/common/SelectOption";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PolicyService from "@/services/payroll/PolicyService";

@Component(
    {
      methods: {
        STATE_LIST() {
          return STATE_LIST
        }
      },
      computed: {
        SelectOption() {
          return SelectOption
        },
        EMPLOYMENT_TYPE_OPTIONS() {
          return EMPLOYMENT_TYPE_OPTIONS
        }
      },
      components: {PortalSelect, Portal, PortalCheckbox, PortalInput}
    }
)
export default class PTOCreationModal extends Vue {

  @Prop()
  private callback!: () => void


  private req: CreatePTOPolicyPayload = new CreatePTOPolicyPayload(
      {
        companyId: this.$wss.getCurrent.id
      }
  )

  private create() {
    ifValid(this, () => {
      Application.startLoading();
	  PolicyService.createPTOPolicy(this.req).then(
          () => {
            Application.stopLoading()
            this.$modal.hideAll();
            this.callback()
          },
          err => processError(err, this)
      )
    })
  }




}
