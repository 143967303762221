import SelectOption from "@/components/common/SelectOption";

export enum EmploymentType {

    STAFF = 'STAFF',
    CONTRACTOR = 'CONTRACTOR',
    FOREIGN = 'FOREIGN'

}

export const EMPLOYMENT_TYPE_OPTIONS: Array<SelectOption> = [
    SelectOption.builder().title("All").value(null).build(),
    SelectOption.builder().title("Employee").value(EmploymentType.STAFF).build(),
    SelectOption.builder().title("Contractor").value(EmploymentType.CONTRACTOR).build(),
    SelectOption.builder().title("Foreign contractor").value(EmploymentType.FOREIGN).build()
]