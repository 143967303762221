


























import {Component, Vue} from "vue-property-decorator";
import PayrollDetailsReportView from "@/components/payroll/business/employments/employee/PayrollDetailsReportView.vue";
import PaystubReportView from "@/components/payroll/business/employments/employee/PaystubReportView.vue";
import GeneralPayrollReportView from "@/components/payroll/business/employments/employee/GeneralPayrollReportView.vue";
import {Prop} from "vue-property-decorator/lib/decorators/Prop";
import {PayrollReport, ReportType} from "@/components/payroll/business/employments/employee/PayrollReport";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import {prettyEnum} from "@/utils/StringUtils";

@Component({
	methods: {
		prettyEnum(val: string) {
			return prettyEnum(val)
		}
	},
	components: {BTPortalCollapse}
})
export default class ReportView extends Vue {

	@Prop()
	private report!: PayrollReport

	@Prop()
	private callBack!: () => void;

	private view: typeof Vue | null = null;

	public created() {
		switch (this.report.reportType) {
			case ReportType.GENERAL:
				this.view = GeneralPayrollReportView;
				break;
			case ReportType.PAYROLL_DETAILS:
				this.view = PayrollDetailsReportView;
				break;
			default:
				this.view = PaystubReportView;
		}
	}

}

