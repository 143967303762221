









































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {PayrollDetailsDTO} from "@/dto/payroll/PayrollDTO";
import GeneralReportControlPanelModal
	from "@/components/payroll/business/employments/employee/GeneralReportControlPanelModal.vue";
import PayrollReportControlPanelModal
	from "@/components/payroll/business/employments/employee/PayrollReportControlPanelModal.vue";

@Component
export default class PayrollDetailsReportView extends Vue {
	@Prop()
	private report!: PayrollDetailsDTO

	@Prop({default: true})
	private showControlButton!: boolean

	@Prop({default: function () {return;}})
	private callBack!: () => void

	private showControlPanel() {
		this.$modal.show(
			PayrollReportControlPanelModal,
			{
				report: this.report,
				callBack: () => this.callBack()
			}
		)
	}
}

