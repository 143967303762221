














import {Vue, Component} from 'vue-property-decorator';
import PTOView from "@/components/payroll/policies/pto/PTOView.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import Application from "@/state/Application";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {processError} from "@/utils/ComponentUtils";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import SLPView from "@/components/payroll/policies/sickLeave/SLPView.vue";

@Component({
	components: {SLPView, BTPortalCollapse, PTOView}
})
export default class IndividualSLP extends Vue {

	private employments: Array<IEmployment> = [];

	private filter = new EmploymentFilter(
		{
			detailsId: this.$wss.getCurrent.id
		}
	)

	private loadEmployments() {
		Application.startLoading();
		EmploymentService.getPageByFilter<IEmployment>(this.filter).then(
			res => {
				this.employments = res.data.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created(): void {
		this.loadEmployments()
	}

}
