export enum DocumentSigningStatus {
  CREATED = "CREATED",
  PARTIALLY_SIGNED = "PARTIALLY_SIGNED",
  SIGNED = "SIGNED",
}

export enum SigningStatus {
  ASSIGNED ="ASSIGNED",
  SENT = "SENT",
  OPENED = "OPENED",
  SIGNED = "SIGNED",
  DECLINED = "DECLINED",
}

export enum SignableDocumentType {
  FORM_W4_2023 = "Form W4"
}

export enum LabelType {
  SIGNATURE = "SIGNATURE",
  NAME = "NAME",
}
