















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class DeleteConfirmation extends Vue {

	@Prop()
	onDelete!: () => void;

  @Prop()
  targetName!: string;

  @Prop({default: "delete"})
	operationName!: string

}

