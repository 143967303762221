import { render, staticRenderFns } from "./SickLeaveEditModal.vue?vue&type=template&id=faba8ad4&scoped=true&"
import script from "./SickLeaveEditModal.vue?vue&type=script&lang=ts&"
export * from "./SickLeaveEditModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faba8ad4",
  null
  
)

export default component.exports