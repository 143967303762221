
























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {EventBus} from "@/utils/EventBus";
import { PublicRIDCContractorDTO } from "@/dto/request/ridc/PublicRIDCContractorDTO";
import { namespace } from "vuex-class";

const Auth = namespace("Auth");

@Component({})
export default class PortalCollapse extends Vue {

  @Prop({default: false})
  private isOpen!: boolean;
  private isOpenInternal = this.isOpen;

  @Prop({default: null})
  private group!: string;

  @Prop()
  private id!: string | number;

  @Prop()
  private title!: string;

  @Prop({ default: null, required: false })
  private requestRIDC!: PublicRIDCContractorDTO;

  @Prop({default: false})
  private fixCollapsed!: boolean

  @Prop({default: null})
  private triangleReplacerIcon!: string;

  @Prop({default: () => ({})})
  private triangleReplacerIconStyle!: Record<string, string | number> | string;

  @Prop({default: null})
  private onExpand!: (() => void) | null;

  @Prop({default: false})
  private selectableHeader!: boolean;

  @Prop({default: null})
  private badge!: string;

  private groupEventName = `PortalCollapse_${this.group}`;

  @Auth.Getter
  private isAdmin!: boolean;

  @Watch('isOpen')
  onPropertyChanged(value: boolean, oldValue: boolean) {
    this.isOpenInternal = value;
  }

  mounted() {
    if(this.fixCollapsed){
      this.isOpenInternal = false;
    }
    if (this.group) {
      if (!this.id) {
        this.id = "collapse_" + Math.random();
      }
      EventBus.$on(this.groupEventName, this.onExpandEvent);
    }
  }

  beforeDestroy() {
    EventBus.$off(this.groupEventName);
  }

  toggle() {
    if(this.fixCollapsed){
      return;
    }
    this.isOpenInternal = !this.isOpenInternal;
    if (this.group && this.isOpenInternal) {
      EventBus.$emit(this.groupEventName, {id: this.id});
    }
    if(this.isOpenInternal && this.onExpand){
      this.onExpand();
    }
  }

  onExpandEvent(event: PortalCollapseEvent) {
    if (event.id != this.id) {
      this.isOpenInternal = false;
    }
  }

  get isExpanded(){
    return this.isOpenInternal && !this.fixCollapsed;
  }

}

interface PortalCollapseEvent {
  id: string
}
