











import {Vue, Component, Prop} from 'vue-property-decorator';
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "@/utils/StringUtils";
import Application from "@/state/Application";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {processError} from "@/utils/ComponentUtils";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import {SickLeave} from "@/components/payroll/sickLeave/SickLeave";

@Component({
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		prettyEnum
	}
})
export default class SickLeaveOptionsModal extends Vue {

	@Prop()
	private sickLeave!: SickLeave

	@Prop()
	private callback!: () => void

	private delete() {
		Application.startLoading()
		SickLeaveService.deleteSickLeaveRecord(this.sickLeave.id, this.sickLeave.employment.id!).then(
			() => {
				this.$modal.hideAll()
				Application.stopLoading()
				this.callback()
			},
			err => processError(err, this)
		)
	}

	private deleteSickLeave() {
		this.$modal.show(
			DeleteConfirmation,
			{
				onDelete: () => this.delete(),
				targetName: "sick leave"
			}
		)
	}


}

